import { AlignJustify } from 'lucide-react';

export function MobileButton() {
  return (
    <AlignJustify
      className="block cursor-pointer md:hidden"
      onClick={() => {
        const menu = document.getElementById('menu-items');
        menu.classList.toggle('hidden');
      }}
    />
  );
}
